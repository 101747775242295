<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('missing_documents').toUpper() }}</h5>
        <b-table bordered
                 striped
                 responsive
                 show-empty
                 :items="missingDocuments"
                 :fields="missingDocumentsFields"
                 class="mb-4 table-dropdown border rounded">
            <template #cell(document_type_name)="row">
                {{ getLocaleText(row.item, 'document_type_name') }}
            </template>
            <template #cell(document_due_date)="row">
                {{ row.item.document_due_date | dateFormat }}
            </template>
            <template #empty="scope">
                <div class="text-center py-2">{{ $t('no_result_1') }}</div>
            </template>
        </b-table>
        <b-button variant="primary"
                  @click="updateFormShow"
                  v-if="checkPermission('studentprogram_updatemissingdocuments') && editMode">
            {{ $t('edit') }}
        </b-button>
        <CommonModal ref="formModal"
                     size="xl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="clearFormData()"
                     v-if="checkPermission('studentprogram_updatemissingdocuments') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <missing-document-form :studentProgram="studentProgram"
                                       @updateFormSuccess="updateFormSuccess()"
                                       v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Components
    import CommonModal from '@/components/elements/CommonModal';

    // Page
    import MissingDocumentForm from './UpdateForm';

    export default {
        components: {
            CommonModal,
            MissingDocumentForm
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formProcess: null,
                studentId: null,
                missingDocuments: [],
                missingDocumentsFields: [
                    {
                        key: 'document_type_name',
                        label: this.$t('missing_document').toUpper(),
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: true
                    },
                    {
                        key: 'document_due_date',
                        label: this.$t('document_due_date').toUpper(),
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: true
                    }
                ]
            }
        },
        watch: {
            studentProgram: {
                handler(value) {
                    this.missingDocuments = value.missing_documents;
                },
                immediate: true
            }
        },
        created() {
            this.studentId = this.$route.params.id;
            this.missingDocuments = this.studentProgram.missing_documents
        },
        methods: {
            // Clear
            clearFormData() {
                this.formProcess = null
            },

            // Update
            updateFormShow() {
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            }
        }
    }
</script>

