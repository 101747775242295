<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('documents').toUpper() }}</h5>
        <div class="d-flex justify-content-end mb-3">
            <b-button variant="primary" @click="showCreateDocumentModal()">
                {{ $t('create_document') }}
            </b-button>
        </div>
        <hr v-show="loading" />
        <div class="position-relative">
            <loading v-show="loading" :description-show="true" />
            <b-table bordered striped responsive :items="documents" :fields="documentFields"
                :empty-text="$t('there_are_no_records')" show-empty
                class="mb-3 table-dropdown no-scrollbar border rounded" v-show="!loading">
                <template #cell(date)="data">
                    {{ data }}
                    {{ data.value }}
                </template>
                <template #cell(type)="data">
                    {{ data.value }}
                </template>
                <template #cell(description)="data">
                    {{ data.value }}
                </template>

                <template #cell(download)="data">
                    <b-button :long-request="true" variant="primary" size="sm" block
                        @click="downloadFile(data.item)">
                        {{ $t('download').toUpper() }}
                    </b-button>
                </template>
            </b-table>
        </div>
        <CommonModal ref="modal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('documents_upload') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="row mb-5 align-items-center">
                    <div class="col-12">
                        <ValidationObserver ref="storeForm">
                            <div class="row">
                                <div class="col-12">
                                    <ValidationProvider name="type" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group>
                                            <label>{{ $t('student_documents') }}</label>
                                            <parameter-selectbox v-model="form.type" code="student_document_types"
                                                sort="id" :validate-error="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="file" rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('documents_upload')">
                                            <div class="d-flex custom-file-upload">
                                                <b-form-file v-model="form.file" :state="errors[0] ? false : null"
                                                    :placeholder="$t('select_file')" :drop-placeholder="$t('drop_file')"
                                                    ref="fileInput" multiple></b-form-file>
                                                <b-button variant="outline-secondary"
                                                    @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse')
                                                    }}</b-button>
                                            </div>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]">
                                            </div>
                                            <div class="alert alert-info mt-2">{{ $t('warning_file') }}</div>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('explanation')">
                                            <b-input-group class="mb-1">
                                                <b-form-input v-model="form.explanation"
                                                    :state="errors[0] ? false : null" />
                                            </b-input-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                    <div class="col-12 mt-3 d-flex">
                        <b-button @click="fileUpload" type="button" variant="primary" class="btn-lg mr-2">
                            {{ $t('save') | toUpperCase }}
                        </b-button>
                    </div>
                </div>
            </template>
        </CommonModal>
        <!--<MissingDocument :student-program="this.studentProgram" />-->
    </div>
</template>
<script>
// Component
import Loading from '@/components/elements/Loading';
import CommonModal from "@/components/elements/CommonModal.vue";
// Service
import StudentProgramService from '@/services/StudentProgramService';
//import MissingDocument from "../missingDocument/Index.vue"


export default {
    components: {
        CommonModal,
        Loading,
        //MissingDocument
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: {},
            documents: [],
            documentFields: [
                {
                    key: 'created_at',
                    label: this.toUpperCase('date'),
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'align-middle pd-1 width-110 text-center'
                },
                {
                    key: 'type',
                    label: this.toUpperCase('type'),
                    sortable: true,
                    tdClass: 'align-middle pd-1 '
                },
                {
                    key: 'description',
                    label: this.toUpperCase('explanation'),
                    sortable: true,
                    tdClass: 'align-middle pd-1 '
                },

                {
                    key: 'download',
                    label: '',
                    sortable: false,
                    tdClass: 'align-middle pd-1 width-100'
                }
            ],
            loading: false
        }
    },
    mounted() {
        this.getDocument()
    },
    methods: {
        formClear() {
            this.form = {
                type: null,
                file: null
            }
        },
        showCreateDocumentModal() {
            this.$refs.modal.$refs.commonModal.show()
        },
        getDocument() {
            StudentProgramService.document(this.studentProgram.student_id)
                .then(response => {
                    this.documents = response.data.data
                })
                .catch((e) => {
                    this.showErrors(e)
                })
        },
        downloadFile(item) {
            StudentProgramService.downloadFile(item.uuid)
                .then(response => {
                    this._downloadFile(response, item.file_name)

                }).catch(err => {
                    this.showErrors(err)
                })
        },
        async fileUpload() {
            const valid = await this.$refs.storeForm.validate();
            if (!valid) return
            const formData = new FormData();
            formData.append("type", this.form.type);
            formData.append("explanation", this.form.explanation);
            formData.append("file", this.form.file[0]);
            formData.append("student_id", this.studentProgram.student_id);
            StudentProgramService.fileUpload(formData)
                .then(response => {
                    this.$toast.success(response.data.message)
                    this.$refs.modal.$refs.commonModal.hide()
                    this.getDocument()
                    this.form = {};
                })
                .catch((e) => {
                    this.showErrors(e)
                })
        }

    }
}
</script>
